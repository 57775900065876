export { default as airport } from "./airport.svg"
export { default as date } from "./date.svg"
export { default as dining } from "./dining.svg"
export { default as directions } from "./directions.svg"
export { default as doctor } from "./doctor.svg"
export { default as emergency } from "./emergency.svg"
export { default as flirting } from "./flirting.svg"
export { default as greetings } from "./greetings.svg"
export { default as hotel } from "./hotel.svg"
export { default as manners } from "./manners.svg"
export { default as money } from "./money.svg"
export { default as questions } from "./questions.svg"
export { default as shopping } from "./shopping.svg"
export { default as transportation } from "./transportation.svg"
export { default as weather } from "./weather.svg"
